<template>
  <div>
    <div class="data-header">
      <span class="data-min-title">{{cnName}}</span>
    </div>
    <div class="data-content">
      <h1>最近一周统计：{{time}}</h1>
      <div>
        <strong>安全报警分析</strong>
        <pie :propData="pieData"></pie>
      </div>
      <h1 style="border-top:1px dashed rgb(7, 228, 250,0.2)" />
      <div>
        <strong>作业报警分析</strong>
        <ring :propData="ringData"></ring>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin-all";
import ring from "./aylRing";
import pie from "./aylPie";
export default {
  mixins: [mixin],

  components: { ring, pie },

  data() {
    return {
      //↓/////钩子字段//////////////
      name: "clbjtj",
      cnName: "车辆报警统计",
      api: "/sanitation/dataCloudService/getCarAlarmStatistics",
      isMock: false,
      comData: {
        adasAlarmCount: 0, //ADAS报警
        dsmAlarmCount: 0, //DSM报警
        generalAlarmCount: 0, //普通报警
        regulationAlarmCount: 0, //电子规则报警
        insufficientMileageSpeedAlarmCount: 0, //作业里程不足报警
        lateWashAlarmCount: 0, //清洗作业晚点
        lateSweepAlarmCount: 0, //清扫作业晚点
        operationOverSpeedAlarmCount: 0 //作业超速报警
      },
      //↑////钩子字段//////////////
      time: this.$dayjs().subtract(6, 'day').format("YYYY-MM-DD")+"——"+this.$dayjs().format("YYYY-MM-DD"),
      debug:0,
    };
  },
  computed: {
    pieData() {
      let sf = this;
      if (sf.comData) {
        let arr = [
          {
            name: "ADAS报警",
            value: sf.comData.adasAlarmCount
          },
          { name: "DSM 报警", value: sf.comData.dsmAlarmCount },
          { name: "普 通 报 警", value: sf.comData.generalAlarmCount },
          {
            name: "电子规则报警",
            value: sf.comData.regulationAlarmCount
          }
        ];
        let sum = arr.reduce((sum,item)=>{
          return sum+item.value;
        },0);

        sf.log("sum",sum);

        arr = arr.map(item=>{
          return {name:item.name+" ("+makespace(item.value)+"，"+makespace(((item.value/sum)*100).toFixed(2))+"%)",value:item.value}
        });

        return arr;
      } else {
        return [];
      }
    },
    ringData() {
      let sf = this;
      if (sf.comData) {
        let arr= [
          {
            name: "作业超速报警",
            value: sf.comData.operationOverSpeedAlarmCount
          },
          { name: "清洗作业晚点", value: sf.comData.lateWashAlarmCount },
          { name: "清扫作业晚点", value: sf.comData.lateSweepAlarmCount },
          {
            name: "作业里程不足",
            value: sf.comData.insufficientMileageSpeedAlarmCount
          }
        ];
        let sum = arr.reduce((sum,item)=>{
          return sum+item.value;
        },0);

        sf.log("sum",sum);

        arr = arr.map(item=>{
          return {name:item.name+" ("+makespace(item.value)+"，"+makespace(((item.value/sum)*100).toFixed(2))+"%)",value:item.value}
        });

        return arr;
      } else {
        return [];
      }
    }
  },

  mounted() {
    let self = this;
    if(self.isMock){
      self.comData = getMockData();

      let timer = setInterval(async () => {
        self.comData = getMockData();
      }, 6000);

      self.$once("hook:beforeDestory", () => {
        clearInterval(timer);
        timer = null;
      });
    }
  }
};

function getMockData() {
  return {
    adasAlarmCount: parseInt(Math.random() * 100), //ADAS报警
    dsmAlarmCount: parseInt(Math.random() * 100), //DSM报警
    generalAlarmCount: parseInt(Math.random() * 100), //普通报警
    regulationAlarmCount: parseInt(Math.random() * 100), //电子规则报警

    insufficientMileageSpeedAlarmCount: parseInt(Math.random() * 100), //作业里程不足报警
    lateWashAlarmCount: parseInt(Math.random() * 100), //清洗作业晚点
    lateSweepAlarmCount: parseInt(Math.random() * 100), //清扫作业晚点
    operationOverSpeedAlarmCount: parseInt(Math.random() * 100) //作业超速报警
  };
}

function makespace(v){
  if(v<10)
    return "  "+v;
  else return ""+v;
}
</script>
<style scoped>
.data-content{
  padding: 1.2vw !important
}
.data-content > h1 {
  font-size: 0.6vw;
  color: #fff;
  /* margin-top: -1vh; */
  margin-bottom: 2vh;
  text-align: center;
}
.data-content {
  margin-top: -2vh;
}
.data-content > div {
  margin-bottom: 4vh;
}
.data-content strong {
  display: inline-block;
  margin-bottom: 2vh;
  background: rgba(3, 94, 255, 0.5);
  font-size: 0.6vw;
  padding: 0.8vh 1vh;
  border: 1px solid #07e4fa;
}
</style>